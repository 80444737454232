export default class UserInfo {
    public id!: string;
    public email!: string;
    public firstname!: string;
    public lastname!: string;

    constructor() {
        this.firstname='';
        this.lastname='';
    }
}
