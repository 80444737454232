






























import {Component, Vue} from 'vue-property-decorator';
import {getShowInstallBar, hideInstallBar} from "@/assets/utils/SessionUtils";

@Component({components: {}})
export default class InstallSnackbarAndroid extends Vue {
    private deferredPrompt: any = undefined;
    private showSnackbar: boolean = false;
    private eventHandlerAdded: boolean = false;

    constructor() {
        super();
    }

    mounted() {
        const showBar = getShowInstallBar();

        if (showBar) {
            window.addEventListener("beforeinstallprompt", this.beforeInstall);
            this.eventHandlerAdded = true;
        }
    }

    destroyed() {
        if (this.eventHandlerAdded) {
            window.removeEventListener("beforeinstallprompt", this.beforeInstall);
        }
    }

    private installPwa(): void {
        if (this.deferredPrompt) {
            this.showSnackbar = false;
            this.deferredPrompt.prompt();
            // Wait for the user to respond to the prompt
            this.deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                } else {
                }
                this.deferredPrompt = undefined;
            });
        }
    }

    private beforeInstall(e: any): void {
        e.preventDefault();
        // Stash the event so it can be triggered later.
        this.deferredPrompt = e;
        // Update UI to notify the user they can add to home screen
        this.showSnackbar = true;
    }

    private closeInstallBar(): void {
        this.showSnackbar = false;
        let date = new Date();
        date.setDate(date.getDate() + 2);
        hideInstallBar(date);
    }
}
