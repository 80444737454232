
































































































































import {Component, Ref, Vue, Watch} from 'vue-property-decorator';
import {isLoggedIn} from '@/assets/utils/SessionUtils';
import ProfileSettingsDialog from "@/components/global/dialog/ProfileSettingsDialog.vue";
import {globalEventBus} from "@/main";


@Component({components: {ProfileSettingsDialog}})
export default class Header extends Vue {
    private drawer: boolean = false;
    private selectedLanguage: string = this.$store.getters.lang;
    private openMenu: boolean = false;
    private openProfileMenu: boolean = false;
    private darkMode: boolean = this.$vuetify.theme.dark;
    @Ref()
    private profileSettingsDialog!: ProfileSettingsDialog;
    private get isLoggedIn(): boolean {
        return isLoggedIn();
    }

    private readonly menuItemsNotLoggedIn: { title, icon, to }[] = [
        {title: this.$t('login.heading'), icon: "mdi-login", to: "login"},
        {
            title: this.$t('register.heading'),
            icon: 'mdi-account-plus',
            to: 'register'
        },
    ];
    private readonly menuItems: { title, icon, to , param}[] = [{
        title: this.$t('moodary.heading'),
        icon: 'mdi-book-open-page-variant-outline',
        to: "diary",
        param : {listView: (this.$store.getters.showList ? "list": "calendar")}
    },
        {title: this.$t('trends.heading'), icon: "mdi-chart-bell-curve-cumulative", to: "stats", param:{}},
        {title: this.$t('medicalBag.heading'), icon: "mdi-hospital-box-outline", to: "medi", param: {}},
    ];
    private readonly languages: { key: string, value: string, icon:string }[] = [
        {key: 'de', value: 'Deutsch', icon:'/img/flags/de.svg'},
        {key: 'en', value: 'English', icon:'/img/flags/en.svg'},
        {key: 'es', value: 'Español', icon:'/img/flags/es.svg'}
    ];
    private get themeColor(): string {
        if (this.$store.getters.darkmode) {
            return "#222222";
        }
        return "#f5f5f5";
    }

    async mounted() {
        if(this.isLoggedIn) {
            const settings = await this.$webApi.getUserSettings();
            this.$store.commit("SET_DARKMODE",settings.darkMode);
            this.$store.commit("SET_LANGUAGE",settings.lang);
            this.$store.commit("SET_DIARYSETTINGS",settings.diary);
        }
        this.selectedLanguage=this.getLanguage();
        this.changeDarkMode();

        this.selectedLanguage = this.$i18n.locale;
        document.documentElement.setAttribute("lang", this.selectedLanguage);
        document.getElementById("metaPageDescription")?.setAttribute("content", this.$tc("general.siteDescription"));

        //globalEventBus.$on("showNavigation", this.showNavigation);
    }
private changeDarkMode(): void {
    const darkMode = this.getDarkMode();
    this.$vuetify.theme.dark = darkMode;
    this.darkMode = darkMode ;
    document.getElementById("metaThemeColor")?.setAttribute("content", this.themeColor);
}
    private getDarkMode(): boolean {
        return this.$store.getters.darkmode;
    }
    private getLanguage(): string {
        return this.$store.getters.lang;
    }
    destroyed() {
        //globalEventBus.$off("showNavigation", this.showNavigation);
    }

    private showNavigation() {
        this.drawer = true;
        this.openProfileMenu = false;
        this.openMenu = false;
    }
    private openProfileSettings() {
        this.profileSettingsDialog.show();
    }
    private async saveLanguage() : Promise<void> {
        const language = this.selectedLanguage;
        this.$store.commit("SET_LANGUAGE", language);
        this.$i18n.locale = language;
        this.$vuetify.lang.current = language;
        document.getElementById("htmlLang")?.setAttribute("lang", language);
        if(this.isLoggedIn) {
            await this.$webApi.updateUserSettings({ lang: language, darkMode:this.$store.getters.darkmode, diary: this.$store.getters.diarySettings });
        }
        await this.$router.push("/" + this.$i18n.locale + this.$route.path.substr(3));
        this.$router.go(0);
    }
    private async saveDarkMode(): Promise<void> {
        this.openMenu = false;
        document.getElementById("metaThemeColor")?.setAttribute("content", this.themeColor);
        this.$store.commit("SET_DARKMODE", this.darkMode);
        if(this.isLoggedIn) {
            await this.$webApi.updateUserSettings({ lang: this.selectedLanguage, darkMode:this.darkMode, diary: this.$store.getters.diarySettings });
        }
        this.changeDarkMode();
        globalEventBus.$emit("darkModeChanged");
    }
    private async logOff(userDecision: boolean = false) {
        this.$emit('logOff', userDecision);
    }
    private get darkLogo(): string{
        return require('@/assets/img/moodaryBarIconWhite.svg');
    }
    private get lightLogo(): string{
        return require('@/assets/img/moodaryBarIconBlack.svg');
    }
}
