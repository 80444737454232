











import {Component, Vue} from 'vue-property-decorator';
import DeviceConfig from "@/models/DeviceConfig";
import BrowserUtility from "@/mixin/browserUtility";


@Component({components: {}})
export default class OfflineDetector extends Vue {
    private isOffline:boolean = false;
    private isOnline:boolean = true;
    private deviceConfig:DeviceConfig = BrowserUtility.getDeviceConfig(navigator,window);

    private onOffChanged():void{
        this.isOffline = !navigator.onLine;
    }

    mounted() {
        if(this.deviceConfig.isMobile) {
            window.addEventListener("offline", this.onOffChanged);
            window.addEventListener("online", this.onOffChanged);
        }
    }
    destroyed() {
        if (this.deviceConfig.isMobile) {
            window.removeEventListener("offline", this.onOffChanged);
            window.removeEventListener("online", this.onOffChanged);
        }
    }
}
