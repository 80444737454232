


















import {Component, Vue} from 'vue-property-decorator';

@Component
export default class RefreshPageDialog extends Vue {
    //private countDown:number =  10
    private open:boolean = false;
    public show(): void {
        this.open = true;
        // this.countDown = 10;
        //this.countDownTimer();
    }
    // private countDownTimer() {
    //     if(this.countDown > 0) {
    //         setTimeout(() => {
    //             this.countDown -= 1
    //             this.countDownTimer()
    //         }, 1000)
    //     }else {
    //         this.refreshPage();
    //     }
    // }
    private refreshPage(): void {
        this.$router.go(0);
    }
}
