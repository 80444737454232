



























import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({components: {}})
export default class InstallSnackbarIos extends Vue {

    @Prop({required: true})
    private isStandalone: boolean = false;
    @Prop({required: true})
    private isIPad: boolean = false;

    private get darkMode(): boolean {
        return this.$store.getters.darkmode;
    }

    private get showInstallBar() {
        if (this.isStandalone)
            return false;
        return !this.$store.getters.userCloseInstallBar;
    }

    private closeInstallBar(): void {
        this.$store.commit("SET_USERCLOSEINSTALLBAR", true);
    }
}
