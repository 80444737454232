import '@mdi/font/css/materialdesignicons.min.css' ;// Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import de from "vuetify/lib/locale/de";
import en from "vuetify/lib/locale/en";
import es from "vuetify/lib/locale/es";
import {store} from "@/store/store"

Vue.use(Vuetify);

const opts = {
    lang: {
        locales: {de, en, es},
        current: 'de'
    },
    theme: {
        icons: {
            iconfont: 'mdi'
        },
        dark: store.getters.darkmode,
        themes: {
            light: {
                primary: "#33cc33",
                accent: "#33cc33",
            },
            dark: {
                primary: "#33cc33",
                accent: "#33cc33",
            },
        },
    },
}
export default new Vuetify(opts)
