

















































import {Component, Prop, Ref, Vue, Watch} from 'vue-property-decorator';
import PersistentDialog from "@/components/global/dialog/PersistentDialog.vue";
import ColorPicker from "@/components/global/ColorPicker.vue";
import UserDiarySettings from "@/models/UserDiarySettings";
import DisplayTypeMood from "@/components/global/DisplayTypeMood.vue";
import {MoodType} from "@/models/MoodType";

@Component({components: {DisplayTypeMood, PersistentDialog, ColorPicker}})
export default class UserMoodaryData extends Vue {
    @Prop({required: true})
    private value!: UserDiarySettings;
    private userMoodaryData: UserDiarySettings = new UserDiarySettings();
    private loading: boolean = false;
    private valid: boolean = false;
    /*
    *  Mood5,
    Mood3,
    Thumb,
    Rate5,
    Rate3
    * */
    private moodTypeItems:{id:number,name:string}[]=[
        {id:0, name: this.$tc('profileSettings.moodaryData.moodType.mood5')},
        {id:1, name: this.$tc('profileSettings.moodaryData.moodType.mood3')},
        {id:2, name: this.$tc('profileSettings.moodaryData.moodType.thumb')}
    ];
    @Ref()
    private confirmDialog !: PersistentDialog;

    @Watch('value', {deep: true})
    private valueChanged(): void {
        this.userMoodaryData = JSON.parse(JSON.stringify(this.value));
    }

    private async validate() {
        //@ts-ignore
        if (this.$refs.userMoodaryDataForm.validate()) {
            try {
                this.loading = true;
                await this.$webApi.updateUserSettings({
                    lang: this.$store.getters.lang,
                    darkMode: this.$store.getters.darkmode,
                    diary: this.userMoodaryData
                });
                this.$store.commit("SET_DIARYSETTINGS", this.userMoodaryData);
                this.$emit("input", this.userMoodaryData);
            } catch {
                this.confirmDialog.showError(this.$i18n.tc('profileSettings.userData.alerts.saveError.title'), this.$i18n.tc('profileSettings.userData.alerts.saveError.message'));
            } finally {
                this.loading = false;
            }
        }
    }

    private icons(itemName:string) : string[] {
        let items = new Array<string>();
        itemName.split(',').forEach((item) => {
            items.push(item);
        })
        return items;
    }
    private get userMoodaryDataHasChanged(): boolean {
        return JSON.stringify(this.userMoodaryData) !== JSON.stringify(this.value);
    }

    public reset(): void {
        // @ts-ignore
        this.$refs.userMoodaryDataForm.reset();
    }

    mounted() {
        this.valueChanged();
    }

    private get moods(): number {
        if(this.userMoodaryData.moodType === MoodType.Mood5 ) {
            return 5;
        }

        return 3;
    }

    private get rateComponent() {
        switch (this.userMoodaryData.moodType) {
            case MoodType.Mood5:
                return () => {
                    return import("@/components/global/DisplayTypeMood.vue");
                };
            case MoodType.Mood3:
                return () => {
                    return import("@/components/global/DisplayTypeMood.vue");
                };
            default :
                return () => {
                    return import("@/components/global/DisplayTypeThumb.vue");
                };
        }
    }
}
