import _Vue, {PluginObject} from 'vue';
import WebApiService from "@/assets/service/WebApiService";


const webApiPlugin: PluginObject<any> = {
    install(vue: typeof _Vue): void {
        const webApi = new WebApiService();
        vue.prototype.$webApi = webApi;
        vue.webApi = webApi;
    },
};

export default webApiPlugin;

// add type definitions for typescript compiler
declare module 'vue/types/vue' {
    interface Vue {
         $webApi: WebApiService;
    }

    interface VueConstructor {
        webApi: WebApiService;
    }
}
