

















import {Component, Vue} from 'vue-property-decorator';


@Component({components: {}})
export default class ToTop extends Vue {
    private showToTop: boolean = false;
    private onScroll(e): void {
        if (typeof window === 'undefined') return
        const top = window.pageYOffset || e.target.scrollTop || 0
        this.showToTop = top > 400
    }

    private gotoTop(): void {
        this.$vuetify.goTo(0)
    }
}
