import {MoodType} from "@/models/MoodType";

export default class MoodItem {
    public id: number = 0;
    public date: string = "";
    public time: string  = "";
    public moodValue: number = 0;
    public hint !: string;
    public imageData: string | null;
    public imageName: string | null;
    public color: string = '';


    static create(type:MoodType): MoodItem {
        const now = new Date();
        const time = now.toLocaleTimeString("de", {hour: "2-digit", minute: "2-digit"});
        return new MoodItem(MoodItem.getFormattedDate(new Date()), time, this.moodStandardValue(type), "", null, null,  "");
    }
    static moodStandardValue(type:MoodType): number {
        if(type=== MoodType.Thumb) {
            return 4;
        }
        return 3;
    }
    constructor(date: string, time: string, moodValue: number, hint: string, imageData: string | null, imageName: string | null, dayColor: string) {
        this.time = time;
        this.date = date;
        this.moodValue = moodValue;
        this.hint = hint;
        this.imageData = imageData;
        this.imageName = imageName;
        this.color = dayColor;
    }

    reset(): void {
        const date = new Date();
        this.hint = "";
        this.date = MoodItem.getFormattedDate(date);
        this.time = "";
        this.moodValue = 3;
        this.imageData = null;
        this.imageName = null;
        this.color = '';
    }

    public static getFormattedDate(date: Date, separator: string = "-"): string {
        const dd = String(date.getDate()).padStart(2, '0');
        const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
        const yyyy = date.getFullYear();
        return yyyy + separator + mm + separator + dd;
    }
}

