import Repository from "@/assets/service/Repository";
import {getSid} from "@/assets/utils/SessionUtils";
import MoodItem from "@/models/MoodItem";
import UserSettings from "@/models/UserSettings";
import {globalEventBus} from "@/main";
import DataTableParameter, {DataTableFilter} from "@/models/DataTableOptions";
import UserReminder from "@/models/UserReminder";
import UserInfo from "@/models/UserInfo";
import {AxiosError} from "axios";

export default class WebApiService {

    public async isNoRobot(data: { response: string }): Promise<any> {
        return await Repository.post('/api/captcha/verify.php', data, {
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Accept': 'application/json'
            }
        });
    }

    public async updateUserSettings(settings: any): Promise<void> {
        await Repository.post('/api/userSettings/update.php', settings, {
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Accept': 'application/json',
                'Authorization': `${getSid()}`
            }
        });
        globalEventBus.$emit('userSettingsChanged');
        return;
    }

    public async deleteProfile(lang: string, password: string): Promise<boolean> {
        try {
            const response = await Repository.post('/api/deleteProfile/deleteProfile.php', {
                lang,
                password
            }, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
                    'Accept': 'application/json',
                    'Authorization': `${getSid()}`
                }
            });
            return !!response?.data?.success;
        } catch (e) {
            return false;
        }
    }

    public async resetPasswordConfirmEmail(hash: string, password: string): Promise<boolean> {
        try {
            const requestData = {hash, password};
            const response = await Repository.post('/api/passwordReset/resetPassword.php', requestData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
                    'Accept': 'application/json'
                }
            });
            return response?.data?.success;
        } catch (e) {
            return false;
        }
    }

    public async passwordReset(email: string, lang: string): Promise<boolean> {
        try {
            if (!lang) {
                lang = 'de';
            }
            const requestData = {email: email, lang: lang};
            const response = await Repository.post('/api/passwordReset/sendEmail.php', requestData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
                    'Accept': 'application/json'
                }
            });
            return !!response?.data?.success;

        } catch (e) {
            return false;
        }
    }

    public async refreshToken(): Promise<string | undefined> {
        try {
            const response = await Repository.post('/api/auth/refreshToken.php', {}, {
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Accept': 'application/json',
                    'Authorization': `${getSid()}`
                }
            });
            if (response?.data?.success) {
                return response.data.jwt
            }
            return undefined;
        } catch (e) {
            return undefined;
        }
    }

    public logout(): Promise<void> {
        try {
            const sid = getSid();
            if (sid) {
                return Repository.delete('/api/auth/logout.php', {
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                        'Accept': 'application/json',
                        'Authorization': `${sid}`
                    }
                });
            }
            return Promise.reject();
        } catch (e) {
            return Promise.reject();
        }
    }

    public async login(email: string, password: string, session: string = ''): Promise<string | undefined> {
        try {
            const requestData = {email: email, password: password, sid: session};
            const response = await Repository.post('/api/auth/login.php', requestData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
                    'Accept': 'application/json'
                }
            });
            if (response?.data?.success) {
                return response.data.jwt;
            }
            return undefined;
        } catch (e) {
            return undefined;
        }
    }

    public register(email: string, password: string, firstname: string, lastname: string, lang: string): Promise<boolean> {
        try {
            if (!lang) {
                lang = 'de';
            }
            const requestData = {email, password, firstname, lastname, lang};
            return Repository.post('/api/register/user.php', requestData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
                    'Accept': 'application/json'
                }
            });
        } catch (e) {
            return Promise.reject(false);
        }
    }

    public async confirmEmail(hash: string): Promise<boolean> {
        try {
            const requestData = {hash};
            const response = await Repository.post('/api/register/confirmEmail.php', requestData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
                    'Accept': 'application/json'
                }
            });
            return !!response?.data?.success;
        } catch (e) {
            return false;
        }
    }

    public async deleteMoodEntry(moodItem: MoodItem): Promise<boolean> {
        try {
            const response = await Repository.delete('/api/moodEntry/delete.php', {
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Accept': 'application/json',
                    'Authorization': `${getSid()}`
                }, data: {
                    item: moodItem
                }
            })
            if (response?.data) {
                return response?.data.success;
            }
            return false;
        } catch (e) {
            return false;
        }
    }

    public async countMoodEntries(): Promise<number> {
        try {
            const response = await Repository.get('/api/moodEntry/count.php', {
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Accept': 'application/json',
                    'Authorization': `${getSid()}`
                }
            });
            if (response?.data) {
                return response?.data.count;
            }
            return Promise.reject(0);
        } catch (e) {
            return Promise.reject(0);
        }
    }

    public async updateMoodEntry(moodItem: MoodItem): Promise<number> {
        try {
            const response = await Repository.put('/api/moodEntry/update.php', {item: moodItem}, {
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Accept': 'application/json',
                    'Authorization': `${getSid()}`
                }
            });
            if (response?.data) {
                if (response?.data.success) {
                    globalEventBus.$emit("refreshMoodItems");
                    return moodItem.id;
                }
                return 0;
            }
            return 0;

        } catch (e) {
            return 0;
        }
    }

    public async createReminder(reminder: UserReminder): Promise<UserReminder> {
        const response = await Repository.post<UserReminder>('/api/userReminder/create.php', {item: reminder}, {
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Accept': 'application/json',
                'Authorization': `${getSid()}`
            }
        });
        return response.data;
    }

    public async updateReminder(reminder: UserReminder): Promise<UserReminder> {
        const response = await Repository.post('/api/userReminder/update.php', {item: reminder}, {
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Accept': 'application/json',
                'Authorization': `${getSid()}`
            }
        });
        return response.data.item;
    }

    public async updateUserData(user: UserInfo): Promise<UserInfo> {
        const response = await Repository.post('/api/userData/update.php', {item: user}, {
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Accept': 'application/json',
                'Authorization': `${getSid()}`
            }
        });
        return response.data.item;
    }

    public readUserData(): Promise<UserInfo> {
        try {
            return Repository.post('/api/userData/read.php', {}, {
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Accept': 'application/json',
                    'Authorization': `${getSid()}`
                }
            }).then(result => {
                return result.data;
            });
        } catch (e) {
            return Promise.reject([]);
        }
    }

    public readReminder(): Promise<UserReminder> {
        try {

            return Repository.post('/api/userReminder/read.php', {}, {
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Accept': 'application/json',
                    'Authorization': `${getSid()}`
                }
            }).then(result => {
                return result.data;
            });
        } catch (e) {
            return Promise.reject([]);
        }
    }

    public async addMoodEntry(moodItem: MoodItem): Promise<MoodItem | undefined> {
        try {
            const response = await Repository.post('/api/moodEntry/create.php', {item: moodItem}, {
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Accept': 'application/json',
                    'Authorization': `${getSid()}`
                }
            });
            if (response?.data) {
                globalEventBus.$emit("refreshMoodItems");
                return response?.data.item;
            }
            return undefined;
        } catch (e) {
            return undefined;
        }
    }

    public readMoodEntries(dataTableParameters: DataTableParameter): Promise<any> {
        try {

            return Repository.post('/api/moodEntry/read.php', dataTableParameters, {
                headers: {
                    'Authorization': `${getSid()}`
                }
            }).catch((reason: AxiosError) => {
                if (reason.response?.status === 401) {
                    globalEventBus.$emit("loggOff");
                }
            });
        } catch (e) {
            return Promise.reject([]);
        }
    }

    public readMoodEntriesRange(from: string, to: string, filter: DataTableFilter = new DataTableFilter()): Promise<any> {
        try {
            return Repository.post('/api/moodEntry/read_range.php', {from, to, filter}, {
                headers: {
                    'Authorization': `${getSid()}`
                }
            }).catch((reason: AxiosError) => {
                if (reason.response?.status === 401) {
                    globalEventBus.$emit("loggOff");
                }
            });
        } catch (e) {
            return Promise.reject([]);
        }
    }

    public async readEmergencyBag(): Promise<any> {
        try {
            const response = await Repository.get('/api/emergencyBag/read.php', {
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Accept': 'application/json',
                    'Authorization': `${getSid()}`
                }
            });
            if (response?.data) {
                return response?.data;
            }
            return undefined;
        } catch (e) {
            return undefined;
        }
    }

    public async storeEmergencyBag(text: string, id: number): Promise<number> {
        try {
            const response = await Repository.put('/api/emergencyBag/store.php', {
                id: id,
                text: text
            }, {
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Accept': 'application/json',
                    'Authorization': `${getSid()}`
                }
            });
            if (response?.data) {
                return response?.data.id;
            }
            return 0;
        } catch (e) {
            return 0;
        }
    }

    public async validateToken(): Promise<boolean> {
        try {
            Repository.defaults.headers.common['Authorization'] = getSid();
            const response = await Repository.post('/api/auth/validate_token.php', {}, {
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Accept': 'application/json'
                }
            });
            return !!response?.data?.isValid;
        } catch (e) {
            return false;
        }
    }

    public async showToken(): Promise<any> {
        try {
            Repository.defaults.headers.common['Authorization'] = getSid();
            const response = await Repository.post('/api/auth/show_token.php', {}, {
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Accept': 'application/json'
                }
            });
            if (response?.data?.isValid) {
                return response?.data?.data;
            }
            return undefined;
        } catch (e) {
            return undefined;
        }
    }

    public getUserSettings(): Promise<UserSettings> {
        try {
            return Repository.get('/api/userSettings/read.php', {
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Accept': 'application/json',
                    'Authorization': `${getSid()}`
                }
            }).then((result) => {
                return result.data;
            }).catch(() => {
                return Promise.reject(new UserSettings());
            });
        } catch (e) {
            return Promise.reject(new UserSettings());
        }
    }
}
