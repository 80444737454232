import i18n from "@/plugins/i18n";
import MoodItem from "@/models/MoodItem";

export default class DateUtility {
    public static getFormattedDateRange(dateRange: string[], separator: string = '-'): string {

        if (dateRange && dateRange[0] && dateRange[1]) {
            return i18n.d(new Date(dateRange[0]), "date") + separator + i18n.d(new Date(dateRange[1]), "date");
        }
        return '...'
    }

    public static getCurrentMonthDates(date: Date = new Date()): string[] {
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        return [DateUtility.getDateString(firstDay), DateUtility.getDateString(lastDay)];
    }

    public static getLastMonthDates(): string[] {
        const date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
        const lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
        return [DateUtility.getDateString(firstDay), DateUtility.getDateString(lastDay)];
    }

    public static getCurrentWeekDates(now: Date = new Date()): string[] {
        const startDay = parseInt(i18n.tc('general.firstDayOfWeek'));
        const d = now.getDay(); //get the current day
        let weekStart = new Date(now.valueOf() - (d <= 0 ? 7 - startDay : d - startDay) * 86400000); //rewind to start day
        if (startDay === 0) {
            weekStart = new Date(now.valueOf() - (d < 0 ? 7 - startDay : d - startDay) * 86400000); //rewind to start day
        }
        const weekEnd = new Date(weekStart.valueOf() + 6 * 86400000); //add 6 days to get last day
        return [DateUtility.getDateString(weekStart), DateUtility.getDateString(weekEnd)];
    }

    public static getWeekNumber(date: Date = new Date()): number {
        /// Create a copy of this date object
        const target = new Date(date.valueOf());

        // ISO week date weeks start on Monday, so correct the day number
        const dayNr = (date.getDay() + 6) % 7;

        // ISO 8601 states that week 1 is the week with the first Thursday of that year
        // Set the target date to the Thursday in the target week
        target.setDate(target.getDate() - dayNr + (3));

        // Store the millisecond value of the target date
        const firstThursday = target.valueOf();

        // Set the target to the first Thursday of the year
        // First, set the target to January 1st
        target.setMonth(0, 1);

        // Not a Thursday? Correct the date to the next Thursday
        if (target.getDay() !== 4) {
            target.setMonth(0, 1 + ((4 - target.getDay()) + 7) % 7);
        }

        // The week number is the number of weeks between the first Thursday of the year
        // and the Thursday in the target week (604800000 = 7 * 24 * 3600 * 1000)
        return 1 + Math.ceil((firstThursday - target.valueOf()) / 604800000);

    }

    public static getLastWeekDates(): string[] {
        let now = new Date();

        const startDay = parseInt(i18n.tc('general.firstDayOfWeek'));
        if (startDay > 0) {
            now.setDate(now.getDate() - 7);
        }
        const d = now.getDay(); //get the current day
        const weekStart = new Date(now.valueOf() - (d <= 0 ? 7 - startDay : d - startDay) * 86400000); //rewind to start day
        const weekEnd = new Date(weekStart.valueOf() + 6 * 86400000); //add 6 days to get last day
        return [DateUtility.getDateString(weekStart), DateUtility.getDateString(weekEnd)];
    }

    public static getDateString(date: Date, separator: string = "-"): string {
        return MoodItem.getFormattedDate(date, separator);
    }
}
