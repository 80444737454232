import {deleteCookie, getCookie, setCookie} from '@/assets/utils/CookieUtils.ts';
import JwtInfo from "@/models/JwtInfo";

export function getJwtInfo(): JwtInfo {
    return parseJwt(getSid());
}

function parseJwt(token: string): JwtInfo {
    if (token) {
        try {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            return JSON.parse(jsonPayload);
        } catch (e) {
            return new JwtInfo();
        }

    }
    return new JwtInfo();
}

export function getSid(): string {
    return getCookie('sid');
}

export function setSid(sid: string) {
    const info = parseJwt(sid);
    setCookie('sid', sid, new Date(info.exp * 1000));
}

export function unsetSid() {
    deleteCookie('sid');
}

export function isLoggedIn(): boolean {
    const sid = getSid();
    return sid !== null && sid !== '';
}

export function getShowInstallBar(): boolean {
    const val = getCookie('installbar');
    if (val) {
        return JSON.parse(val);
    } else {
        return true;
    }
}

export function hideInstallBar(date: Date) {
    setCookie('installbar', 'false', date);
    //console.log("hideInstallBar");
}

const timeoutHandle = () => {
    location.reload();
};
