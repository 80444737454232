


















































































import {Component, Ref, Vue} from 'vue-property-decorator';
import PersistentDialog from "@/components/global/dialog/PersistentDialog.vue";
import DeleteProfile from "@/components/global/DeleteProfile.vue";
import ChangeProfile from "@/components/global/UserData.vue";
import UserReminderSettings from "@/components/global/UserReminderSettings.vue";
import UserData from "@/components/global/UserData.vue";
import UserMoodaryData from "@/components/global/UserMoodaryData.vue";
import UserReminder from "@/models/UserReminder";

import UserInfo from "@/models/UserInfo";
import UserDiarySettings from "@/models/UserDiarySettings";


@Component({
    components: { PersistentDialog, DeleteProfile, ChangeProfile, UserReminderSettings,UserData,UserMoodaryData }
})
export default class ProfileSettingsDialog extends Vue {

    private reminder:UserReminder = new UserReminder();
    private userData:UserInfo = new UserInfo();
    private userDiarySettings:UserDiarySettings = new UserDiarySettings();
    private openDialog: boolean = false;

    @Ref()
    private confirmDialog!: PersistentDialog;

    @Ref()
    private deleteProfileComponent!: DeleteProfile;

    @Ref()
    private userDataComponent!: UserData;
    public async show(): Promise<void> {
        this.openDialog = true;
        try {
            this.userDiarySettings = (await this.$webApi.getUserSettings()).diary;
            this.reminder = await this.$webApi.readReminder();
            this.userData= await this.$webApi.readUserData();
        }catch {
            this.reminder = new UserReminder();
            this.userData = new UserInfo();
            this.userDiarySettings = new UserDiarySettings();
        }
    }

    private closeDialog(): void {
        this.openDialog = false;
        if (this.deleteProfileComponent) {
            this.deleteProfileComponent.reset();
        }
        if (this.userDataComponent) {
            this.userDataComponent.reset();
        }
    }
}
