






























import {Component, Prop, Vue} from 'vue-property-decorator';
import ColorPicker from "@/components/global/ColorPicker.vue";
import MoodTypeSettings from "@/models/MoodTypeSettings";
import MoodTypeSetting from "@/models/MoodTypeSetting";

@Component({components: {ColorPicker}})
export default class DisplayTypeMood extends Vue {
    @Prop({default: 5})
    private moods!:number;

    @Prop({required:true, default: new MoodTypeSettings()})
    private value!:MoodTypeSettings;

    private get moodOne() : MoodTypeSetting {
        if(this.value && this.value.one) {
            return this.value.one;
        }
        return new MoodTypeSetting();
    }
    private get moodTwo() : MoodTypeSetting {
        if(this.value && this.value.two) {
            return this.value.two;
        }
        return new MoodTypeSetting();
    }
    private get moodThree() : MoodTypeSetting {
        if(this.value && this.value.three) {
            return this.value.three;
        }
        return new MoodTypeSetting();
    }
    private get moodFour() : MoodTypeSetting {
        if(this.value && this.value.four) {
            return this.value.four;
        }
        return new MoodTypeSetting();
    }
    private get moodFive() : MoodTypeSetting {
        if(this.value && this.value.five) {
            return this.value.five;
        }
        return new MoodTypeSetting();
    }
}
