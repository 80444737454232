export default class DataTableParameter {
    public options: DataTableOptions;
    public filter: DataTableFilter;

    constructor() {
        this.options = new DataTableOptions();
        this.filter = new DataTableFilter();
    }

}

export class DataTableOptions {
    public groupBy: string[];
    public groupDesc: boolean[];
    public sortBy: string[];
    public sortDesc: boolean[];
    public page: number;
    public itemsPerPage: number;
    public mustSort: boolean;
    public multiSort: boolean;

    constructor() {
        this.mustSort = true;
        this.multiSort = false;
        this.groupBy = [];
        this.groupDesc = [];
        this.sortBy = ['dateTime'];
        this.sortDesc = [true];
        this.itemsPerPage = 30;
        this.page = 1;
    }

}

export class DataTableFilter {
    public date!: string
    public moodValue!: number;
    public detail!: string

    constructor() {
        this.date = '';
        this.moodValue = -1;
        this.detail = '';
    }

    public clear(): void {
        this.date = '';
        this.moodValue = -1;
        this.detail = '';
    }


    public isEqual(otherFilter: DataTableFilter | undefined): boolean {
        if (otherFilter) {
            return this.date === otherFilter.date && this.moodValue === otherFilter.moodValue && this.detail === otherFilter.detail;
        }
        return false;
    }
}
