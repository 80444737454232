import Vue from 'vue'
import App from './App.vue';
import router from './router';
import {store} from '@/store/store';
import './registerServiceWorker';
//plugins
import vuetify from "./plugins/vuetify";
import webApiPlugin from "@/plugins/webApi";
import './plugins/tiptap-vuetify';
import i18n from "@/plugins/i18n";
import { ObserveVisibility } from 'vue-observe-visibility'
import Highcharts from 'highcharts';
import ExportingHighcharts from 'highcharts/modules/exporting';
import HighchartsVue from 'highcharts-vue';


Vue.directive('observe-visibility', ObserveVisibility)
ExportingHighcharts(Highcharts);
Vue.use(HighchartsVue);

Vue.mixin({
    methods: {
        isTouchDevice: function (): boolean {
            return (('ontouchstart' in window) ||
                (navigator.maxTouchPoints > 0) ||
                (navigator.msMaxTouchPoints > 0));
        },
    },
});

Vue.config.productionTip = false;
Vue.config.performance = true;
Vue.use(webApiPlugin);
export const globalEventBus = new Vue();



new Vue({
    router,
    vuetify,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app');
