




































import {Component, Ref, Prop, Vue, Watch} from 'vue-property-decorator';
import PersistentDialog from "@/components/global/dialog/PersistentDialog.vue";
import UserReminder from "@/models/UserReminder";

@Component({components: {PersistentDialog}})
export default class UserReminderSettings extends Vue {
    private loading:boolean =false;

    @Prop()
    private value!:UserReminder;
    private reminder:UserReminder=new UserReminder();

    private nonActiveDaysItems:{id:number, name:string}[]=[
        {id:1,name:this.$tc('profileSettings.reminder.nonActiveDays.items[0]')},
        {id:2,name:this.$tc('profileSettings.reminder.nonActiveDays.items[1]')},
        {id:7,name:this.$tc('profileSettings.reminder.nonActiveDays.items[2]')},
        {id:30,name:this.$tc('profileSettings.reminder.nonActiveDays.items[3]')}];

    private valid: boolean = true;

    @Ref()
    private confirmDialog !: PersistentDialog;

    @Watch('value', {deep: true})
    private valueChanged(){
        this.reminder= JSON.parse(JSON.stringify(this.value));
    }

    private get reminderHasChanged():boolean {
        return JSON.stringify(this.reminder) !== JSON.stringify(this.value);
    }
    private async validate() {
        //@ts-ignore
        if (this.$refs.reminderForm.validate() ) {
            try {
                this.loading = true;
                if (this.reminder.id === 0) {
                    const res = await this.$webApi.createReminder(this.reminder);
                    this.reminder.id = res.id;

                } else {
                    await this.$webApi.updateReminder(this.reminder);
                }
                this.$emit("input", this.reminder);
            } catch {
                this.confirmDialog.showError(this.$i18n.tc('profileSettings.reminder.alerts.saveError.title'),this.$i18n.tc('profileSettings.reminder.alerts.saveError.message'));
            } finally {
                this.loading = false;
            }
        }
    }

    async mounted(){
      this.valueChanged();
    }

    private generateIcs() {

    }
}
