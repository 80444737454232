import DateUtility from "@/assets/utils/DateFormatUtil";
import UserDiarySettings from "@/models/UserDiarySettings";

export default class UserSettings {
    public id: number = 0;
    public trendDateRange: string[] = [];
    public trendUserSelection: number = 0;
    public darkMode: boolean = false;
    public lang: string = 'de';
    public userCloseInstallBar: boolean = false;
    public diary:UserDiarySettings = new UserDiarySettings();
    constructor() {
        this.trendDateRange = DateUtility.getCurrentWeekDates();
        this.diary = new UserDiarySettings();
    }

}
