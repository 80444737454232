import Vue from 'vue';
import Vuex from 'vuex';
import trends from "@/store/modules/Trends"
import UserDiarySettings from "@/models/UserDiarySettings";

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        showList: <string>window.localStorage.getItem('showList') || true,
        acceptcookies: <string>window.localStorage.getItem('acceptcookies') || false,
        userCloseInstallBar: JSON.parse(<string>window.localStorage.getItem('userCloseInstallBar')) || false,
        darkmode: JSON.parse(<string>window.localStorage.getItem('darkmode')) || false,
        lang: <string>window.localStorage.getItem('lang') || 'de',
        saAppUs: <string>window.localStorage.getItem('saAppUs') || undefined,
        calendarViewStart: <string>window.localStorage.getItem('calendarViewStart') || undefined,
        diarySettings: <string>window.localStorage.getItem('diarySettings') || undefined
    },
    getters: {
        darkmode: initState => initState.darkmode,
        acceptcookies: (initState) => {
            try {
                const accept = JSON.parse(<string>initState.acceptcookies);
                if (accept === undefined) {
                    return false;
                }
                return accept;
            } catch {
                return false;
            }
        },
        showList: (initState) => {
            try {
                const accept = JSON.parse(<string>initState.showList);
                if (accept === undefined) {
                    return false;
                }
                return accept;
            } catch {
                return false;
            }
        },
        diarySettings: (initState) => {
            try {

                if(initState.diarySettings)
                {
                    return initState.diarySettings;
                }
                return new UserDiarySettings();
            } catch(e) {
                console.log(e);
                return new UserDiarySettings();
            }
        },
        calendarViewStart: (initState) => {
            try {
                if(initState.calendarViewStart)
                {
                    return initState.calendarViewStart;
                }
                return "";
            } catch {
                return "";
            }
        },
        lang: (initState) => {
            const lang = initState.lang;
            if (!lang) {
                return "de";
            }
            return lang;
        },
        userCloseInstallBar: initState => initState.userCloseInstallBar,
        saAppUs: initState => initState.saAppUs,
    },
    mutations: {
        SET_DARKMODE(initState, params) {
            // eslint-disable-next-line no-param-reassign
            initState.darkmode = params;
            if (params !== undefined) {
                window.localStorage.setItem('darkmode', JSON.stringify(params));
            }
        },
        SET_CALENDARVIEWSTART(initState, params) {
            // eslint-disable-next-line no-param-reassign
            initState.calendarViewStart = params;
            if (params !== undefined) {
                window.localStorage.setItem('calendarViewStart',params);
            }
        },
        SET_SHOWLIST(initState, params) {
            // eslint-disable-next-line no-param-reassign
            initState.showList = params;
            if (params !== undefined) {
                window.localStorage.setItem('showList', JSON.stringify(params));
            }
        },
        SET_ACCEPTCOOKIES(initState, params) {
            // eslint-disable-next-line no-param-reassign
            initState.acceptcookies = params;
            if (params !== undefined) {
                window.localStorage.setItem('acceptcookies', JSON.stringify(params));
            }
        },
        SET_USERCLOSEINSTALLBAR(initState, params) {
            // eslint-disable-next-line no-param-reassign
            initState.userCloseInstallBar = params;
            if (params !== undefined) {
                window.localStorage.setItem('userCloseInstallBar', JSON.stringify(params));
            }
        },
        SET_LANGUAGE(initState, params) {
            // eslint-disable-next-line no-param-reassign
            initState.lang = params;
            if (params !== undefined) {
                window.localStorage.setItem('lang', params);
            }
        },
        SET_SAAPPUS(initState, params) {
            // eslint-disable-next-line no-param-reassign
            initState.saAppUs = params;
            if (params !== undefined) {
                window.localStorage.setItem('saAppUs', params);
            }

        },
        SET_DIARYSETTINGS(initState, params) {
            // eslint-disable-next-line no-param-reassign
            initState.diarySettings = params;
            if (params !== undefined) {
                window.localStorage.setItem('diarySettings', JSON.stringify(params));
            }

        }
    },
    actions: {
        cleanup({commit}, {deleteEntries, deleteMedicalBag, deleteDarkMode, deleteLanguage}) {
            if (deleteDarkMode) {
                commit('SET_DARKMODE', false);
            }


            if (deleteLanguage) {
                commit('SET_LANGUAGE', 'de');
            }
            commit('SET_ACCEPTCOOKIES', false);
            commit('SET_USERSELECTION', 0);
            commit('SET_DATERANGE', []);
            commit('SET_USERCLOSEINSTALLBAR', false);
            commit('SET_EXPDATE', 0);
            commit('SET_SAAPPUS', undefined);
        }
    },
    modules: {
        trends,
    },
});
