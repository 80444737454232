export default {
    moodary: {
            heading: 'Diario',
            filter: 'Filtrar',
            emptyDiary: `<p>Aun no has guardado mucho en tu diario...</p>
                    <p>Empieza ahora sin registrarte.</p>
                        <p>Tus datos seran guardados en tu navegador
web. Por eso no es necesario que te registres.</p>`,
            noDataText: 'No se encontraron caprichos',
            loadingText: 'Cargando ...',
            noResultsText: 'No se encontraron caprichos que coincidan',
            saveError: 'No se pudo guardar la entrada',
        dataView: {
            gotoList : "Mostrar lista",
            gotoCalendar : "Mostrar calendario",
        },
            entry: {
                newTitle: 'Nueva entrada',
                editTitle: 'Editar entrada',
                viewTitle: 'Entrada',
                detailLabel: 'Detalle',
                detailHint: 'Aquí puedes describir con más detalle cómo te sientes.',
                timeLabel: 'Hora',
                timeHint: '',
                dateLabel: 'Fecha',
                dateHint: '',
                imageLabel: 'Imagen',
                imageHint: '',
                colorLabel: 'Color',
                additional: 'Adicional',
                fullDay:'-',
            },
            table: {
                columnDateTime: {title: 'Fecha / hora'},
                columnMood: {title: 'Capricho'},
                columnDetail: {title: 'Detalle'}
            },
            confirmation: {
                doDeleteTitle: '¿Claro?',
                doDeleteText: '¿Estás seguro/a de que deseas eliminar la entrada?',
            }
        },
    trends: {
            heading: 'Estadísticas',
            period: {
                day: 'Día',
                weekDay: 'Día laborable',
                dayTime: 'Hora del día'
            },
            seriesDialog: {
                title: 'Entradas del '
            },
            dateRangeCompareDialog: {
                title: 'Comparación de períodos',
                typeLabel: 'Comparación de períodos',
                calendarWeek: '{year} - Semana del año {week}',
                series: {
                    title: {
                        label: 'Titulo',
                        hint: 'Si deja este campo en blanco, la fecha se utilizará como título.'
                    },
                },
                types: {
                    day: "Día",
                    week: "Semana",
                    month: "Mes",
                    year: "Año"
                }
            },
            dateRangeDialog: {
                title: 'Rango de fechas',
                typeOfLabel: 'Tipo',
                rangeLabel: 'Período',
                buttons: {
                    thisWeek: 'Esta semana',
                    lastWeek: 'La semana pasada',
                    thisMonth: 'Este mes',
                    lastMonth: 'El mes pasado',
                    customRange: 'Selección de usuario',
                    typeDay: 'Dia',
                    typeWeek: 'Semana',
                    typeMonth: 'Mes'
                }
            }
        },
    medicalBag: {
            heading: 'Maleta de emergencia',
            editBag: 'Editar',
            emergencyText: '<h1>Describe tu plan de contingencia.</h1><p>Puedes almacenar el formato con los botones</p>',
            saveError: 'Lamentablemente, tus cambios no se pudieron guardar.',
            emptyEmergencyBag: '<h1>Maleta de emergencia</h1><p>Describe tu bolsa de emergencia</p><p>Puedes utilizar los botones para formatear su texto.</p>',
            clearText: 'Eliminar texto',
            sizeExceeded: 'Desafortunadamente, tu maleta de emergencia se ha vuelto demasiado grande. El tamaño máximo es de 8MB'
        },
    general: {
        notFound:{
            text:"<p>Lo sentimos, no se pudo encontrar la URL solicitada.</p><p>Haga clic <a href='/'>aquí</a> para volver a conectarse.</p>",
        },
        swUpdateAvailable:{
            title:'Actualización disponible',
            text:'<p>Actualice la página para instalar la actualización.</p>',
            buttons: {
                refresh: 'Actualizar'
            }
        },
        installBar: {
            installButton: "Instalar",
            installLaterButton: "Luego",
            description: "<h4>Buenas noticias</h4><p>También puedes instalar Moodary como una aplicación</p>",
            descriptionSafariBottom: `<h4>Buenas noticias</h4>
<p>También puedes agregar fácilmente Moodary a la pantalla de inicio</p>
<ol>
<li>Toca el botón Compartir en la parte inferior de la pantalla. <i
class="mdi mdi-export-variant"></i> .</li>
<li>Grifo <b>añadir a Homescreen</b> <i class="mdi mdi-plus-box"></i> .</li>
</ol>`,
            descriptionSafariTop: `<h4>Buenas noticias</h4>
<p>También puedes agregar fácilmente Moodary a la pantalla de inicio</p>
<ol>
<li>Toca el botón Compartir en la parte superior de la pantalla. <i
class="mdi mdi-export-variant"></i> .</li>
<li>Grifo <b>añadir a Homescreen</b> <i class="mdi mdi-plus-box"></i> .</li>
</ol>`
        },
        password: "Contraseña",
        logOff: "Desconectar",
        edit: 'editar',
        search: "Buscar",
        close: 'Concluir',
        cancel: 'Mañana',
        save: 'Ahorrar',
        sortByText: 'Ordenar por',
        yes: 'Si',
        no: 'No',
        ok: 'Okay',
        add: 'Agregar',
        remove: 'Retirar',
        delete: 'Claro',
        language: 'Idioma',
        darkMode: 'Modo oscuro',
        dataSettings: 'Administrar los datos ',
        profileSettings: 'Ajustes',
        firstDayOfWeek: '1',
        date: 'Fecha',
        mood: 'Capricho',
        imprint: 'Información legal',
        install: 'Install',
        colors: {
            noColor: '',
            white: 'Blanco',
            silver: 'Plata',
            gray: 'Gris',
            black: 'Negro',
            red: 'Rojo',
            maroon: 'Granate',
            yellow: 'Amarillo',
            olive: 'Aceituna',
            lime: 'Lima',
            green: 'Verde',
            aqua: 'Aqua',
            teal: 'Verfe azulado',
            blue: 'Azul',
            navy: 'Armada',
            fuchsia: 'Fucsia',
            purple: 'Púrpura',
            orange: 'Naranja'
        },
        siteDescription: "Moodary: tu libro de estados de ánimo. Registra tus sentimientos que has sentido durante este dia",
        weekdays: {
            mon: 'Lunes',
            tue: 'Martes',
            wed: 'Miércoles',
            thr: 'Jueves',
            fri: 'Viernes',
            sat: 'Sábado',
            sun: 'Domingo',
        },
        weekDaysOrder: [1,2,3,4,5,6,0],
        download: 'Descargar',
        offline: {
            title: "Desconectado",
            text: `<p>Estas desconectado.</p>
                   <p>Cuando vuelva a estar en línea, Moodary estará completamente disponible nuevamente.</p>`,
        }
    },
    profileSettings: {
        userData: {
            title: 'Datos del usuario',
            alerts: {
                saveError: {
                    title: 'Error',
                    message: 'Desafortunadamente, ha ocurrido un error.'
                }
            }
        },
        reminder: {
            title: 'Recordatorio',
            active: 'Activo',
            nonActiveDays : {
                label: 'Días inactivos',
                hint: '',
                items: ['1 día', '2 días', '1 semana', '1 mes'],
            },
            alerts: {
                saveError: {
                    title: 'Error',
                    message: 'Desafortunadamente, ha ocurrido un error.'
                }
            }
        },
        deleteProfile: {
            title: 'Borrar perfil',
            description: '<p>Si eliminas tu perfil, todas tus entradas / casos de emergencia también se eliminarán irrevocablemente.</p><p>Si estás seguro de esto, confirma tu contraseña nuevamente para estar seguro y continuar.</p>',


        success: '<p>Su perfil ha sido eliminado</p>',
            error: '<p>Lamentablemente, se produjo un error cuando intentamos eliminar su perfil.</p>',
            uccessByeBye: '<p> Lamentamos mucho que haya eliminado su perfil. </p> <p> </p> <p> ¡Le deseamos el mayor de los éxitos en su vida! </p>',
            titleSucceed: 'Perfil eliminado',
            confirm: {
                title: '¿Borrar perfil?',
                message: '<p>¿Su <u>realmente</u> desea eliminar su perfil?</p>',
            }
        },
        moodaryData: {
            presetTime: '¿Establecer la hora automáticamente?',
            presetColors: 'Estándares de color',
            moodType: {
                text:'Tipo de evaluación',
                mood5: 'mdi-emoticon-excited,mdi-emoticon-happy,mdi-emoticon-neutral,mdi-emoticon-sad,mdi-emoticon-cry',
                mood3: 'mdi-emoticon-happy,mdi-emoticon-neutral,mdi-emoticon-sad',
                thumb: 'mdi-thumb-up,mdi-thumb-down'
            },
        },
        moodaryEntryTemplate: {
            title: 'Plantilla de entrada',
            dateInfo: 'La fecha no se puede preasignar',
        },
        importExportData: {
            title: 'Importación/Exportación'
        },
        importData: {
            title: "Importar",
            description: "¿Cómo se deben manejar tus datos existentes?",
            importEntries: 'Importar entradas',
            importMedicalBag: 'Importar maleta de emergencia',
            importLanguage: 'Importar configuración de idioma',
            importDarkMode: 'Importar modo oscuro',
            overwriteData: 'Sobrescribir datos',
            mergeData: 'Integrar datos',
            importError: 'El imprte ha fallado.'
        },
        exportData: {
            title: "Exportar",
            description: "¿Qué contenido de tus datos te gustaría exportar?",
            exportEntries: 'Exportar entradas',
            exportMedicalBag: 'Exportar maleta de emergencia',
            exportLanguage: 'Exportar configuración de idioma',
            exportDarkMode: 'Importar modo oscuro',
            exportSuccess: {title: 'Export', message: 'Tus datos se han exportado correctamente.'},
            exportError: {
                title: 'Export - Error',
                message: 'Desafortunadamente, se produjo un error al exportar tus datos.'
            },
        },
        deleteData: {
            title: "Eliminar datos de usuario",
            description: "<p>¡Aquí puedes borrar tus datos!</p><p>Pero tienes que tener en cuenta que estos no se pueden restaurar.</p>",
            confirmation: {
                doDeleteTitle: '¿Claro?',
                doDeleteAppDataText: '¿Realmente deseas eliminar los datos del usuario?'
            },
            deleteEntries: "Claro entradas",
            deleteMedicalBag: "Claro maleta de emergencia'",
            deleteLanguage: "Claro configuración de idioma",
            deleteDarkMode: "Claro modo oscuro",

        }
    },
    footer: {
        followMoodary: 'Sigue a Moodary también en las redes sociales'
    },
    register: {
        heading: "Registrar",
        new: {
            email: "Email",
            confirmPassword: "Repite la contraseña",
            lastName: 'Apellido',
            firstName: 'Nombre propio',
            validation: {
                emailRequired: 'Debes ingresar tu dirección de correo electrónico.',
                emailValid: 'Tu dirección de correo electrónico debe ser una dirección de correo electrónico.',
                passwordRequired: 'Debes ingresar una contraseña.',
                passwordMinLength: 'Tu contraseña debe tener al menos 8 caracteres.',
                passwordConfirm: 'Las contraseñas no coinciden'
            },
            success: "<p>Te has registrado con éxito.</p><p>Simplemente confirma la recepción del correo electrónico y finalmente podrás comenzar.</p>",
            failed: "<p>Desafortunadamente, no pudimos registrarte.</p>"
        },
        confirmEmail: {
            heading: 'Confirmación de correo electrónico',
            success: '<p>Gracias por confirmar tu dirección de correo electrónico.</p><p>¡Puedes empezar ahora!</p><p>Te deseamos mucha diversión y un buen momento.</p>',
            failed: '<p>Desafortunadamente, algo salió mal con la confirmación.</p><p>Por favor, inténtalo de nuevo más tarde.</p><p>¡Nos disculpamos por las molestias!</p>'
        }
    },
    login: {
        heading: "Entrada",
        passwordReset: 'Restablecer la contraseña',
        passwordForgot: '¿Has olvidado tu contraseña?',
        email: 'Email',
        password: 'Contraseña',
        errorText: 'Verifica la dirección de correo electrónico y la contraseña que ingresaste',
        forgotPassword: '¿Has olvidado tu contraseña?'
    },
    passwordReset: {
        emailConfirmation: {
            heading: 'Restablecer la contraseña',
            sendSuccess: 'Le acabamos de enviar un correo electrónico para confirmar.',
            text: 'Ahora puede restablecer su contraseña.',
            failed: 'Desafortunadamente, algo salió mal. Por favor, inténtelo de nuevo más tarde.',
            password: 'Contraseña',
            passwordRepeat: 'repite la contraseña',
            gotoLogin: 'Atrás para iniciar sesión',
            confirmButton: 'Restablecer la contraseña',
            resetSuccess: 'Tu contraseña ha sido cambiada exitosamente.',
        }
    }
}
