import Vue from 'vue';
import Router, {RawLocation, Route} from 'vue-router';
import i18n from '@/plugins/i18n'
import vuetify from '@/plugins/vuetify'
import {store} from '@/store/store'
import {isLoggedIn, setSid} from '@/assets/utils/SessionUtils';
import {globalEventBus} from '@/main';
import BrowserUtility from "@/mixin/browserUtility";
import WebApiService from "@/assets/service/WebApiService";

Vue.use(Router);
const webApi = new WebApiService();
const routes = [
    {
        path: "/",
        redirect: "/" + store.getters.lang + "/diary",
    },
    {
        path: "/acceptcookies",
        name: 'acceptCookies',
        component: () => {
            //@ts-ignore
            return import("@/views/AcceptCookies.vue")
        },
    },
    {
        path: '/:lang',
        component: {template: "<router-view></router-view>"},
        beforeEnter(to, from, next) {
            const language = to.params.lang;

            if (!["de", "es", "en"].includes(language)) {
                next("de/diary");
            }

            store.commit("SET_LANGUAGE", language);

            if (i18n.locale != language) {
                i18n.locale = language;
            }

            if (vuetify.framework.lang.current != language) {
                vuetify.framework.lang.current = language;
            }

            next();
        },
        children: [
            {
                path: '',
                name: 'root',
                redirect: 'diary',
            },
            {
                path: 'diary',
                name: 'diary',
                redirect:()=> "/" + store.getters.lang + "/diary/" + (store.getters.showList ? "list": "calendar"),
                meta: {
                    loginRequired: true,
                }
            },
            {
                path: 'diary/:listView',
                component: () => {
                    return import("@/views/Moodary.vue")
                },
                meta: {
                    loginRequired: true,
                }
            },
            {
                path: 'stats',
                name: 'stats',
                component: () => {
                    return import("@/views/Trends.vue")
                },
                meta: {
                    loginRequired: true,
                }
            },
            {
                path: 'medi',
                name: 'medi',
                component: () => {
                    return import("@/views/Medicalbag.vue")
                },
                meta: {
                    loginRequired: true,
                }
            },
            {
                path: 'imprint',
                name: 'imprint',
                component: () => {
                    return import("@/views/Imprint.vue")
                },
            },
            {
                path: 'install',
                name: 'install',
                component: () => {
                    return import("@/views/InstallPwa.vue")
                },
            },
            {
                path: 'register',
                name: 'register',
                component: () => {
                    return import("@/views/Register.vue")
                },
            },
            {
                path: 'confirmEmail',
                name: 'confirmEmail',
                component: () => {
                    return import("@/views/ConfirmEmail.vue")
                },
            }, {
                path: 'prce',
                name: 'prce',
                component: () => {
                    return import("@/views/PasswordResetConfirmEmail.vue")
                }
            },
            {
                path: 'profileDeleted',
                name: 'profileDeleted',
                component: () => {
                    return import("@/views/DeleteProfileSucceed.vue")
                }
            },
            {
                path: 'login',
                name: 'login',
                component: () => {
                    return import("@/views/LogIn.vue")
                }
            },
            {
                path: 'passwordReset',
                name: 'passwordReset',
                component: () => {
                    return import("@/views/PasswordReset.vue")
                }
            },
            {
                path: 'generateEntries',
                name: 'generateEntries',
                component: () => {
                    return import("@/views/GenerateEntries.vue")
                },
                meta: {
                    loginRequired: true,
                }
            },
            {
                path: '*',
                component: () => {
                    return import("@/views/NotFound.vue")
                },
                meta: {
                    loginRequired: false,
                }
            },
        ]
    }
];

const router: Router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(async (to: Route,
                         from: Route,
                         next: (to?: RawLocation | false | void) => void) => {
    const acceptCookies = store.getters.acceptcookies;
    const language = to.params.lang ? store.getters.lang: to.params.lang;
    if (!acceptCookies && to.name !== 'acceptCookies') {
        next('acceptCookies');
    } else {
        if (to.matched.some(record => record.meta.loginRequired)) {
            const device = BrowserUtility.getDeviceConfig(navigator, window);
            const uc = store.getters.saAppUs;
            if (!isLoggedIn() && device.isStandalone && uc) {
                try {
                    console.log(atob(uc));
                    const cred = JSON.parse(atob(uc));
                    if (cred && cred.email && cred.pass) {
                        const response = await webApi.login(cred.email, cred.pass, cred.sid);
                        if (response) {
                            setSid(response);
                            router.go(0);
                        } else {
                            globalEventBus.$emit("loggOff");
                            next({path: "/" + language + '/login'});
                        }
                    } else {
                        globalEventBus.$emit("loggOff");
                        next({path: "/" + language + '/login'});
                    }
                } catch {
                    globalEventBus.$emit("loggOff");
                    next({path: "/" + language + '/login'});
                }
            } else if (!isLoggedIn()) {
                globalEventBus.$emit("loggOff");
                next({path: "/" + language + '/login'});
            } else if (to.name === 'login') {
                next({path: "/" + language + '/diary'});
            } else {
                next()
            }
        } else if (to.name === 'login' && isLoggedIn()) {
            next({path: "/" + language + '/diary'});
        } else {
            next();
        }
    }
});

export default router;
