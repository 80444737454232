





import {Component, Vue} from 'vue-property-decorator';
import InstallSnackbarSafari from "@/components/global/installBar/InstallSnackbarSafari.vue";
import InstallSnackbarChrome from "@/components/global/installBar/InstallSnackbarChrome.vue";
import DeviceConfig from "@/models/DeviceConfig";
import BrowserUtility from "@/mixin/browserUtility";

@Component({components: {InstallSnackbarSafari, InstallSnackbarChrome}})
export default class InstallSnackbar extends Vue {

    private deviceConfig: DeviceConfig;

    constructor() {
        super();

        this.deviceConfig = BrowserUtility.getDeviceConfig(navigator, window);
    }

}
