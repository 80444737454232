export default {
    moodary: {
            heading: 'Diary',
            filter: 'Filter',
            emptyDiary: `<p>Until now, not much has happened in your diary...</p>
                        <p>Start now without registration.</p>
                        <p>Your input will be saved in your Browser, therefore it is not necessary to be registered.</p>`,
            noDataText: 'No entries',
            loadingText: 'Loading ...',
            noResultsText: 'No entries found',
            saveError: 'Unfortunately the entry could not be saved',
        dataView: {
            gotoList : "Show list",
            gotoCalendar : "Show calendar",
        },
            entry: {
                newTitle: 'New entry',
                editTitle: 'Edit entry',
                viewTitle: 'Entry',
                detailLabel: 'Details',
                detailHint: 'Please describe your feelings.',
                timeLabel: 'Time',
                timeHint: '',
                dateLabel: 'Date',
                dateHint: '',
                imageLabel: 'Image',
                imageHint: '',
                colorLabel: 'Color',
                additional: 'Additional',
                fullDay:'-',
            },
            table: {
                columnDateTime: {title: 'Date / Time'},
                columnMood: {title: 'Mood'},
                columnDetail: {title: 'Details'}
            },
            confirmation: {
                doDeleteTitle: 'Delete?',
                doDeleteText: 'Do you really want to delete the entry?',
            }
        },
    trends: {
            heading: 'Statistics',
            period: {
                day: 'Day',
                weekDay: 'Weekday',
                dayTime: 'Daytime'
            },
            seriesDialog: {
                title: 'Entries from '
            },
            dateRangeCompareDialog: {
                title: 'Daterange compare',
                typeLabel: 'Daterange compare',
                calendarWeek: 'CW {week} {year}',
                series: {
                    title: {
                        label: 'Title',
                        hint: 'If you leave this field blank, the date will be used as the title.'
                    },
                },
                types: {
                    day: "Day",
                    week: "Week",
                    month: "Month",
                    year: "Year"
                }
            },
            dateRangeDialog: {
                title: 'Daterange',
                typeOfLabel: 'Type',
                rangeLabel: 'Daterange',
                buttons: {
                    thisWeek: 'This week',
                    lastWeek: 'Last week',
                    thisMonth: 'This month',
                    lastMonth: 'Last month',
                    customRange: 'User selection',
                    typeDay: 'Day',
                    typeWeek: 'Week',
                    typeMonth: 'Month'
                }
            }
        },
    medicalBag:{
            heading: 'Emergencybag',
            editBag: 'Edit',
            emergencyText: '<h1>Your Emergencytext</h1><p>Please describe your plans</p>',
            saveError: 'Unfortunately your changes could not be saved.',
            emptyEmergencyBag: '<h1>Emergencybag</h1><p>Describe your Emergencybag.</p><p>You can use the buttons to format your text.</p>',
            clearText: 'Clear text',
            sizeExceeded: 'Unfortunately, your emergency text has become too big. The maximum size is 8MB.'
        },
    general: {
        notFound:{
            text:"<p>Sorry, the requested url could not be found.</p><p>Please click <a href='/'>here</a> to connect again.</p>",
        },
        swUpdateAvailable:{
            title:'Update available',
            text:'<p>Refresh the page to install the update.</p>',
            buttons: {
                refresh: 'Refresh'
            }
        },
        installBar: {
            installButton: "Install",
            installLaterButton: "Later",
            description: "<h4>Good News</h4><p>You can also easily install Moodary as an app</p>",
            descriptionSafariBottom: `<h4>Good News</h4>
<p>You can also easily add Moodary to the home screen</p>
<ol>
<li>Tap the Share button at the bottom of the screen <i class="mdi mdi-export-variant"></i> .</li>
<li>Tap <b>add to Homescreen</b> <i class="mdi mdi-plus-box"></i> .</li>
</ol>
`,
            descriptionSafariTop: `<h4>Good News</h4>
<p>DYou can also easily add Moodary to the home screen</p>
<ol>
<li>Tap the Share button at the top of the screen <i class="mdi mdi-export-variant"></i> .</li>
<li>Tap <b>add to Homescreen</b> <i class="mdi mdi-plus-box"></i> .</li>
</ol>
`
        },
        password: "Password",
        logOff: "Sign out",
        edit: 'edit',
        search: "Search",
        close: 'Close',
        cancel: 'Cancel',
        save: 'Save',
        sortByText: 'Sort by',
        yes: 'Yes',
        no: 'No',
        ok: 'Ok',
        add: 'Add',
        remove: 'Remove',
        delete: 'Delete',
        language: 'Language',
        darkMode: 'Dark mode',
        dataSettings: 'Manage user data',
        profileSettings: 'Settings',
        firstDayOfWeek: '0',
        date: 'Date',
        mood: 'Mood',
        imprint: 'Legal notice',
        install: 'Install',
        colors: {
            noColor: '',
            white: 'White',
            silver: 'Silver',
            gray: 'Gray',
            black: 'Black',
            red: 'Red',
            maroon: 'Maroon',
            yellow: 'Yellow',
            olive: 'Olive',
            lime: 'Lime',
            green: 'Green',
            aqua: 'Aqua',
            teal: 'Teal',
            blue: 'Blue',
            navy: 'Navy',
            fuchsia: 'Fuchsia',
            purple: 'Purple',
            orange: 'Orange'
        },
        siteDescription: "Moodary - your mood book. Capture the feelings that touch you during the day.",
        weekdays: {
            mon: 'Monday',
            tue: 'Tuesday',
            wed: 'Wednesday',
            thr: 'Thursday',
            fri: 'Friday',
            sat: 'Saturday',
            sun: 'Sunday',
        },
        weekDaysOrder: [0,1,2,3,4,5,6],
        download: 'Download',
        offline: {
            title: "Offline",
            text: `<p>You are offline.</p>
                   <p>When you are back online, Moodary will be fully available again.</p>`,
        }
    },
    profileSettings: {
        userData: {
            title: 'User data',
            alerts: {
                saveError: {
                    title: 'Failure',
                    message: 'Unfortunately, an error has occurred.'
                }
            }
        },
        reminder: {
            title: 'Reminder',
            active: 'Active',
            nonActiveDays : {
                label: 'Inactive Days',
                hint: '',
                items: ['1 day', '2 days', '1 week', '1 month'],
            },
            alerts: {
                saveError: {
                    title: 'Failure',
                    message: 'Unfortunately, an error has occurred.'
                }
            }
        },
        deleteProfile: {
            title: 'Delete profile',
            description: '<p> If you delete your profile, then all of your entries / emergency cases will also be irrevocably deleted </p> <p> If you are sure of this, confirm your password again to be on the safe side and continue </p>',
            success: '<p>Your profile has been deleted.</p>',
            error: '<p>Unfortunately there was an error when we tried to delete your profile.</p>',
            successByeBye: '<p> We are very sorry that you have deleted your profile. </p> <p> </p> <p> We wish you every success in your life! </p>',
            titleSucceed: 'Profile deleted',
            confirm: {
                title: 'Delete profile?',
                message: '<p>Do your <u>really</u> want to delete your profile?</p>',
            }
        },
        moodaryData: {
            presetTime: 'Set time automatically?',
            presetColors: 'Color presets',
            moodType: {
                text:'Ratetype',
                mood5: 'mdi-emoticon-excited,mdi-emoticon-happy,mdi-emoticon-neutral,mdi-emoticon-sad,mdi-emoticon-cry',
                mood3: 'mdi-emoticon-happy,mdi-emoticon-neutral,mdi-emoticon-sad',
                thumb: 'mdi-thumb-up,mdi-thumb-down'
            },
        },
        moodaryEntryTemplate: {
            title: 'Entry template',
            dateInfo: 'The date can not be pre-assigned',
        },
        importExportData: {
            title: 'Import/Export'
        },
        importData: {
            title: "Import",
            description: "How should your existing data be handled?",
            importEntries: 'Import entries',
            importMedicalBag: 'Import medicalBag',
            importLanguage: 'Import language settings',
            importDarkMode: 'Import darkMode settings',
            overwriteData: 'Overwrite data',
            mergeData: 'Integrate data',
            importError: 'The import failed!'
        },
        exportData: {
            title: "Export",
            description: "What content of your data would you like to export?",
            exportEntries: 'Export my entries',
            exportMedicalBag: 'Export my medicalBag',
            exportLanguage: 'Export my language settings',
            exportDarkMode: 'Export my darMode settings',
            exportSuccess: {title: 'Export', message: 'Your data has been exported successfully.'},
            exportError: {
                title: 'Export - Error',
                message: 'Unfortunately an error occurred while exporting your data.'
            },
        },
        deleteData: {
            title: "Delete userdata",
            description: "<p>Here you can delete your data.</p><p>But keep in mind that these cannot then be restored.</p>",
            confirmation: {
                doDeleteTitle: 'Delete?',
                doDeleteAppDataText: 'Do you really want to delete your data?'
            },
            deleteEntries: "Delete entries",
            deleteMedicalBag: "Delete medicalBag",
            deleteLanguage: "Delete language settings",
            deleteDarkMode: "Delete darkMode settings",

        }
    },
    footer: {
        followMoodary: 'Follow Moodary on social media too'
    },
    register: {
        heading: "Register",
        new: {
            email: "Email",
            password: "Password",
            confirmPassword: "Repeat password",
            lastName: 'Lastname',
            firstName: 'Firstname',
            validation: {
                emailRequired: 'You have to enter your email address.',
                emailValid: 'Your email address must be an email address.',
                passwordRequired: 'You have to enter a password.',
                passwordMinLength: 'Your password must be at least 8 characters long.',
                passwordConfirm: 'The passwords doesn\'t match'
            },
            success: "<p>You have been successfully registered.</p><p>Just confirm the receipt of the email and then you can finally get started.</p>",
            failed: "<p>Unfortunately we could not register you.</p>"
        },
        confirmEmail: {
            heading: 'E-mail confirmation',
            success: '<p>Thank you for confirming your email address.</p><p>You can start now!</p><p>We wish you a lot of fun and a good time.</p>',
            failed: '<p>Unfortunately something went wrong with the confirmation.</p><p>Please try again later.</p><p>We apologize for the inconvenience!</p>'
        }
    },
    login: {
        heading: "Log In",
        passwordReset: 'Reset password',
        passwordForgot: 'Forgot Password?',
        email: 'Email',
        password: 'Password',
        errorText: 'Please check the email address and password you entered',
        forgotPassword: 'Forgot password?'
    },
    passwordReset: {
        emailConfirmation: {
            heading: 'Reset password',
            sendSuccess: 'We have just sent you an email to confirm.',
            text: 'You can now reset your password.',
            failed: 'Unfortunately, something went wrong. Please try again later.',
            password: 'Password',
            passwordRepeat: 'Repeat password',
            gotoLogin: 'Back to Login',
            confirmButton: 'Reset password',
            resetSuccess: 'Your password has been changed successfully.',
        }
    }
}
