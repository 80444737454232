import DateRangeCompare from "@/models/DateRangeCompare";

const state = {
    dateRange: JSON.parse(<string>window.localStorage.getItem('dateRange')) || new Array<string>(),
    dateRangeCompare: <DateRangeCompare>JSON.parse(<string>window.localStorage.getItem('dateRangeCompare')) || new DateRangeCompare(),
    userSelection: JSON.parse(<string>window.localStorage.getItem('userSelection')) || 0,
    rangeType: JSON.parse(<string>window.localStorage.getItem('rangeType')) || 0,
};

const getters = {
    dateRange: initState => initState.dateRange,
    userSelection: initState => initState.userSelection,
    rangeType: initState => initState.rangeType,
    dateRangeCompare: initState => initState.dateRangeCompare,
};

const mutations = {
    SET_DATERANGE(initState, params) {
        // eslint-disable-next-line no-param-reassign
        initState.dateRange = params;
        if (params) {
            window.localStorage.setItem('dateRange', JSON.stringify(params));
        }
    },
    SET_USERSELECTION(initState, params) {
        // eslint-disable-next-line no-param-reassign
        initState.userSelection = params;
        window.localStorage.setItem('userSelection', JSON.stringify(params));
    },
    SET_RANGETYPE(initState, params) {
        // eslint-disable-next-line no-param-reassign
        initState.rangeType = params;
        window.localStorage.setItem('rangeType', JSON.stringify(params));
    },
    SET_DATERANGECOMPARE(initState, params) {
        // eslint-disable-next-line no-param-reassign
        initState.dateRangeCompare = params;
        if (params) {
            window.localStorage.setItem('dateRangeCompare', JSON.stringify(params));
        }
    },
};

export default {
    state,
    getters,
    mutations,
};
