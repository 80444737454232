import DeviceConfig from "@/models/DeviceConfig";

export default class BrowserUtility {

    static getDeviceConfig(navigator: Navigator, window: Window): DeviceConfig {
        const uaString = navigator.userAgent.toLowerCase();
        let isChrome = /chrome/.test(uaString);
        let isExplorer = /msie/.test(uaString);
        let isExplorer_11 = /rv:11/.test(uaString);
        let isFirefox = /firefox/.test(uaString);
        let isSafari = /safari/.test(uaString);
        let isOpera = /opr/.test(uaString);
        let isEdgeDesktop = /edge/.test(uaString);
        let isEdgeiOS = /edgios/.test(uaString);
        let isEdgeAndroid = /edga/.test(uaString);
        let isIPad = /ipad/.test(uaString);
        let isIOS = /ipad|iphone|ipod/.test(uaString);
        let isMobile = /mobile/.test(uaString);
        let isStandalone = window.matchMedia('(display-mode: standalone)').matches;
        if ((isChrome) && (isSafari)) {
            isSafari = false;
        }
        if ((isChrome) && ((isEdgeDesktop) ||
            (isEdgeiOS) ||
            (isEdgeAndroid))) {
            isChrome = false;
        }
        if ((isSafari) && ((isEdgeDesktop) ||
            (isEdgeiOS) ||
            (isEdgeAndroid))) {
            isSafari = false;
        }
        if ((isChrome) && (isOpera)) {
            isChrome = false;
        }
        const config = {
            isChrome: isChrome,
            isExplorer: isExplorer,
            isExplorer_11: isExplorer_11,
            isFirefox: isFirefox,
            isSafari: isSafari,
            isOpera: isOpera,
            isEdgeDesktop: isEdgeDesktop,
            isEdgeiOS: isEdgeiOS,
            isEdgeAndroid: isEdgeAndroid,
            userAgent: uaString,
            isIOS: isIOS,
            isMobile: isMobile,
            isIPad: isIPad,
            isStandalone: isStandalone
        }
        return config;
    }
}
