














































import {Component, Ref, Vue} from 'vue-property-decorator';

import {unsetSid} from "@/assets/utils/SessionUtils";
import PersistentDialog from "@/components/global/dialog/PersistentDialog.vue";

@Component({components: {PersistentDialog}})
export default class DeleteProfile extends Vue {
    private password: string = '';
    private valid: boolean = true;
    private showPassword: boolean = false;
    private passWordRules = {
        required: value => !!value || this.$tc('register.new.validation.passwordRequired'),
    };
    private confirmProfileDeleteError: boolean = false;
    @Ref()
    private confirmDialog !: PersistentDialog;

    private validate() {
        //@ts-ignore
        if (this.$refs.deleteProfileForm.validate()) {
            this.confirmDialog.showConfirmation(this.$tc('profileSettings.deleteProfile.confirm.title'), this.$tc('profileSettings.deleteProfile.confirm.message'), async () => {
                const success = await this.$webApi.deleteProfile(this.$store.getters.lang, this.password);

                if (success) {
                    await this.$webApi.logout();
                    unsetSid();
                    await this.$router.replace('profileDeleted');
                    this.$router.go(0);
                }
                this.confirmProfileDeleteError = !success;
            });
        }
    }

    private errorCommit(): void {
        this.password = '';
        this.confirmProfileDeleteError = false;
        this.$emit('errorCommit');
    }

    public reset(): void {
        // @ts-ignore
        this.$refs.deleteProfileForm.reset();

    }
}
