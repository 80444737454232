import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from '@/i18n';

Vue.use(VueI18n);
const dateTimeFormats = {
    de: {
        time: {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        },
        date: {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        },
        dateTime: {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        },
        month: {
            month: 'long',
            year: 'numeric'
        },
        year: {
            year: 'numeric'
        },
    },
    en: {
        time: {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        },
        date: {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        },
        dateTime: {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        },
        month: {
            month: 'long',
            year: 'numeric'
        },
        year: {
            year: 'numeric'
        },
    },
    es: {
        time: {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        },
        date: {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        },
        dateTime: {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        },
        month: {
            month: 'long',
            year: 'numeric'
        },
        year: {
            year: 'numeric'
        },
    }
};
export default new VueI18n({
    locale: 'de',
    fallbackLocale: 'de',
    messages,
    dateTimeFormats
});


