export default {
    moodary: {
            heading: 'Tagebuch',
            filter: 'Filter',
            dataView: {
              gotoList : "Zur Listenansicht",
              gotoCalendar : "Zur Kalenderansicht",
            },
            emptyDiary: `<p>Es ist noch nicht vie los in Ihrem Tagebuch...</p>
               <p>Starten Sie jetzt ohne Anmeldung</p>
               <p>Ihre Daten werden in Ihrem Browser gespeichert. Deshalb ist eine Registrierung bisher <u>nicht erforderlich</u>.</p>`,
            noDataText: 'Keine Launen gefunden',
            loadingText: 'Lade Laune ...',
            noResultsText: 'Keine entsprechenden Launen gefunden',
            saveError: 'Leider konnte der Eintrag nicht gespeichert werden',
            entry: {
                newTitle: 'Neuer Eintrag',
                editTitle: 'Eintrag editieren',
                viewTitle: 'Eintrag',
                detailLabel: 'Detail',
                detailHint: 'Hier kannst Du näher beschreiben wie Du dich fühlst.',
                timeLabel: 'Uhrzeit',
                timeHint: '',
                dateLabel: 'Datum',
                dateHint: '',
                imageLabel: 'Bild',
                imageHint: '',
                colorLabel: 'Farbe',
                additional: 'Weiteres',
                fullDay:'-',
            },
            table: {

                columnDateTime: {title: 'Datum/Uhrzeit'},
                columnMood: {title: 'Laune'},
                columnDetail: {title: 'Detail'}
            },
            confirmation: {
                doDeleteTitle: 'Löschen?',
                doDeleteText: 'Möchten Sie den Eintrag wirklich löschen?',
            }
        },
    trends: {
            heading: 'Statistik',
            period: {
                day: 'Tag',
                weekDay: 'Wochentag',
                dayTime: 'Tageszeit'
            },
            seriesDialog: {
                title: 'Einträge vom '
            },
            dateRangeCompareDialog: {
                title: 'Zeitraum Vergleich',
                typeLabel: 'Zeitraum Vergleich',
                calendarWeek: 'KW {week} {year}',
                series: {
                    title: {
                        label: 'Titel',
                        hint: 'Wenn Du dieses Feld leer lässt, dann wird das Datum als Titel verwendet.'
                    },

                },
                types: {
                    day: "Tag",
                    week: "Woche",
                    month: "Monat",
                    year: "Jahr"
                },

            },
            dateRangeDialog: {
                title: 'Zeitraum',
                typeOfLabel: 'Typ',
                rangeLabel: 'Zeitraum',
                buttons: {
                    thisWeek: 'Diese Woche',
                    lastWeek: 'Letzte Woche',
                    thisMonth: 'Dieser Monat',
                    lastMonth: 'Letzter Monat',
                    customRange: 'Benutzerdefiniert',
                    typeDay: 'Tag',
                    typeWeek: 'Woche',
                    typeMonth: 'Monat'
                }
            }
        },
    medicalBag: {
            heading: 'Notfallkoffer',
            editBag: 'Bearbeiten',
            emergencyText: '<h1>Beschreibe deinen Notfallplan</h1><p>Formatierungen kannst Du mit den Buttons hinterlegen</p>',
            saveError: 'Leider konnten Ihre Änderungen nicht gespeichert werden.',
            emptyEmergencyBag: '<h1>Dein Notfallkoffer</h1><p>Hier kannst du deinen Notfallkoffer beschreiben.</p><p>Drücke dazu einfach den "Editieren" Knopf.</p>',
            clearText: 'Text entfernen',
            sizeExceeded: 'Dein Notfallkokoffer ist leider zu groß geworden. Die maximale Größe beträgt 8MB'
        },
    general: {
        notFound:{
            text:"<p>Leider konnte die angefragte Url nicht gefunden werden.</p><p>Bitte klicke <a href='/'>hier</a> um Dich erneut zu verbinden.</p>",
        },
        swUpdateAvailable:{
            title:'Update verfügbar',
            text:'<p>Aktualisiere die Seite um das Update zu installieren.</p>',
            buttons: {
                refresh: 'Aktualisieren'
            }
        },
        installBar: {
            installButton: "Installieren",
            installLaterButton: "Später",
            description: "<h4>Gute Neuigkeiten</h4><p>Du kannnst Moodary auch ganz einfach als App installieren</p>",
            descriptionSafariBottom: `<h4>Gute Neuigkeiten</h4>
<p>Du kannnst Moodary auch ganz einfach zum Startbildschirm hinzufügen</p>
<ol>
<li>Tippe unten auf dem Bildschirm auf die Schaltfläche Teilen <i class="mdi mdi-export-variant"></i> .</li>
<li>Tippe auf <b>zum Homescreen hinzufügen</b> <i class="mdi mdi-plus-box"></i> .</li>
</ol>
`,
            descriptionSafariTop: `<h4>Gute Neuigkeiten</h4>
<p>Du kannnst Moodary auch ganz einfach zum Startbildschirm hinzufügen</p>
<ol>
<li>Tippe oben auf dem Bildschirm auf die Schaltfläche Teilen <i class="mdi mdi-export-variant"></i> .</li>
<li>Tippe auf <b>zum Homescreen hinzufügen</b> <i class="mdi mdi-plus-box"></i> .</li>
</ol>
`
        },
        password: "Passwort",
        logOff: "Abmelden",
        edit: 'editieren',
        search: "Suche",
        close: 'Schließen',
        cancel: 'Abbrechen',
        save: 'Speichern',
        sortByText: 'Sortieren nach',
        yes: 'Ja',
        no: 'Nein',
        ok: 'Ok',
        add: 'Hinzufügen',
        remove: 'Entfernen',
        delete: 'Löschen',
        language: 'Sprache',
        darkMode: 'Nachtmodus',
        dataSettings: 'Einstellungen',
        profileSettings: 'Einstellungen',
        firstDayOfWeek: '1',
        date: 'Datum',
        mood: 'Laune',
        imprint: 'Impressum',
        install: 'Installieren',
        colors: {
            noColor: 'KEINE',
            white: 'Weiß',
            silver: 'Silber',
            gray: 'Grau',
            black: 'Schwarz',
            red: 'Rot',
            maroon: 'Kastanienbraun',
            yellow: 'Gelb',
            olive: 'Oliv',
            lime: 'Limette',
            green: 'Grün',
            aqua: 'Aqua',
            teal: 'Blaugrün',
            blue: 'Blau',
            navy: 'Marineblau',
            fuchsia: 'Fuchsia',
            purple: 'Lila',
            orange: 'Orange'
        },
        siteDescription: "Moodary - Dein Launebuch. Erfasse hier die Gefühle die Dich im Laufe des Tages berühren.",
        weekdays: {
            mon: 'Montag',
            tue: 'Dienstag',
            wed: 'Mittwoch',
            thr: 'Donnerstag',
            fri: 'Freitag',
            sat: 'Samstag',
            sun: 'Sonntag',
        },
        weekDaysOrder: [1,2,3,4,5,6,0],
        download: 'Herunterladen',
        offline: {
            title: "Offline",
            text: `<p>Du bist offline.</p>
                   <p>Wenn Du wieder online bist, dann ist Moodary wieder voll und ganz für dich verfügbar.</p>`,
        }
    },
    profileSettings: {
        userData: {
            title: 'Benutzerdaten',
            alerts: {
                saveError: {
                    title: 'Fehler',
                    message: 'Es ist leider zu einem Fehler gekommen.'
                }
            }
        },
        deleteProfile: {
            title: 'Profil löschen',
            description: '<p>Wenn Du dein Profil löschst, dann sind auch alle deine Einträge/Notfallkoffer/etc unwiderruflich gelöscht</p><p>Wenn Du dir dessen sicher bist dann bestätige zur Sicherheit nochmal dein Passwort und fahre fort</p>',
            success: '<p>Dein Profil wurde endgültig gelöscht.</p>',
            error: '<p>Leider ist es zu einem Fehler gekommen, als wir Dein Profil löschen wollten</p>',
            successByeBye: '<p>Es tut uns sehr Leid, dass Du uns verlässt.</p><p></p><p>Wir wünschen Dir noch viel Erfolg in deinem Leben!</p>',
            titleSucceed: 'Profil gelöscht',
            confirm: {
                title: 'Profil löschen                               ?',
                message: '<p>Willst Du Dein Profil wirklich <u>unwiderruflich</u> löschen?</p>',
            }
        },
        reminder: {
            title: 'Erinnerung',
            active: 'Aktiv',
            nonActiveDays : {
                label: 'Inaktive Tage',
                hint: '',
                items: ['1 Tag', '2 Tage', '1 Woche', '1 Monat'],
            },
            alerts: {
                saveError: {
                    title: 'Fehler',
                    message: 'Es ist leider zu einem Fehler gekommen.'
                }
            }
        },
        moodaryData: {
            presetTime: 'Zeit automatisch setzen?',
            presetColors: 'Farb Voreinstellung',
            moodType: {
                text:'Bewertungsart',
                mood5: 'mdi-emoticon-excited,mdi-emoticon-happy,mdi-emoticon-neutral,mdi-emoticon-sad,mdi-emoticon-cry',
                mood3: 'mdi-emoticon-happy,mdi-emoticon-neutral,mdi-emoticon-sad',
                thumb: 'mdi-thumb-up,mdi-thumb-down'
            },
        },
        moodaryEntryTemplate: {
            title: 'Eintrag Vorlage',
            dateInfo: 'Das Datum kann nicht vorbelegt werden',
        },
        importExportData: {
            title: 'Import/Export'
        },
        importData: {
            title: "Importieren",
            description: "Wie soll mit Deinen existierenden Daten verfahren werden?",
            importEntries: 'Einträge importieren',
            importMedicalBag: 'Notfallkoffer importieren',
            importLanguage: 'Spracheinstellungen importieren',
            importDarkMode: 'Nachtmodus importieren',
            overwriteData: 'Daten überschreiben',
            mergeData: 'Daten integrieren',
            importError: 'Der Import ist fehlegeschlagen!'
        },
        exportData: {
            title: "Exportieren",
            description: "Welche Inhalte deiner Daten möchtest Du exportieren?",
            exportEntries: 'Einträge exportieren',
            exportMedicalBag: 'Notfallkoffer exportieren',
            exportLanguage: 'Spracheinstellungen exportieren',
            exportDarkMode: 'Nachtmodus exportieren',
            exportSuccess: {title: 'Export', message: 'Deine Daten wurden erfolgreich exportiert.'},
            exportError: {
                title: 'Export - Error',
                message: 'Leider ist beim Export deiner Daten ein Fehler aufgetreten.'
            },
        },
        deleteData: {
            title: "Löschen",
            description: "<p>Hier kannst du deine Daten löschen!</p><p>Aber bedenke dass diese dann nicht wieder herstellbar sind.</p>",
            confirmation: {
                doDeleteTitle: 'Löschen?',
                doDeleteAppDataText: 'Möchtest Du die Benutzerdaten wirklich löschen?'
            },
            deleteEntries: "Einträge löschen",
            deleteMedicalBag: "Notfallkoffer löschen",
            deleteLanguage: "Spracheinstellungen löschen",
            deleteDarkMode: "Nachtmodus löschen",
        }
    },
    footer: {
        followMoodary: 'Folge Moodary auch über die sozialen Medien'
    },
    register: {
        heading: "Registrieren",
        new: {
            email: "Email",
            confirmPassword: "Passwort wiederholen",
            lastName: 'Nachname',
            firstName: 'Vorname',
            validation: {
                emailRequired: 'Deine Email Adresse musst Du angeben.',
                emailValid: 'Deine Email Adresse muss eine Email Adresse sein.',
                passwordRequired: 'Du musst ein Passwort angeben.',
                passwordMinLength: 'Dein Passwort muss mindestens 8 Zeichen lang sein.',
                passwordConfirm: 'Die Passworte stimmen nicht überein'
            },
            success: "<p>Du wurdest erfolfreich registriert.</p><p>Bestätige nur noch den Erhalt der Email und dann kannst Du endlich loslegen.</p>",
            failed: "<p>Wir konnten Dich leider nicht registrieren.</p>",
        },
        confirmEmail: {
            heading: 'EMail Bestätigung',
            success: '<p>Vielen Dank für die Bestätigung deiner Emailadresse.</p><p>Du kannst jetzt loslegen!</p><p>Wir wünschen Dir viel Spaß und eine gute Zeit.</p>',
            failed: '<p>Leider ist bei der Bestätigung etwas schief gelaufen</p><p>Bitte versuche es zu einem späteren Zeitpunkt noch einmal.</p><p>Wir entschuldigen uns für diese Umstände!</p>'
        }
    },
    login: {
        heading: "Anmelden",
        passwordReset: 'Passwort zurücksetzen',
        passwordForgot: 'Passwort vergessen?',
        email: 'Email',
        password: 'Passwort',
        errorText: 'Bitte überprüfe die angegebene Email Adresse und das Passwort',
        forgotPassword: 'Passwort vergessen?'
    },
    passwordReset: {
        emailConfirmation: {
            heading: 'Passwort zurücksetzen',
            sendSuccess: 'Wir haben Dir soeben eine Email zur Bestätigung geschickt.',
            text: 'Du kannst jetzt Dein Passwort zurücksetzen.',
            failed: 'Leider ist etwas schief gelaufen. Bitte versuche es zu einem späteren Zeitpunkt noch einmal.',
            password: 'Passwort',
            passwordRepeat: 'Passwort wiederholen',
            gotoLogin: 'Zur Anmeldung',
            confirmButton: 'Passwort zurücksetzen',
            resetSuccess: 'Dein Passwort wurde erfolgreich geändert.',
        }
    }
}
