



























import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

@Component({components: {}})
export default class ColorPicker extends Vue {

    @Prop()
    private label: string = this.$tc('moodary.entry.colorLabel');
    @Prop({required: true})
    private value!: string;
    private valueCopy: string = '';
    private open: boolean = false;


    @Watch('value')
    private valueChanged(): void {
        if (this.value) {
            this.valueCopy = JSON.parse(JSON.stringify(this.value));

        } else {
            this.valueCopy = '#FFFFFF00';
        }
    }

    private get swatchStyle() {
        if (this.valueCopy.length > 7 && !this.valueCopy.endsWith('00')) {
            return {backgroundColor: this.valueCopy.substr(0, 7)};
        } else {
            return {backgroundColor: 'transparent'};
        }
    }

    private get backgroundColor(): string {
        return this.valueCopy;
    }

    private clearColor(): void {
        this.valueCopy = '';
        this.colorChanged();
    }

    private colorChanged() {
        this.open = false;
        this.$emit("input", this.valueCopy);

    }

    mounted() {
        this.valueChanged();
    }
}
