




















































import {Component, Prop, Ref, Vue, Watch} from 'vue-property-decorator';
import PersistentDialog from "@/components/global/dialog/PersistentDialog.vue";
import UserInfo from "@/models/UserInfo";

@Component({components: {PersistentDialog}})
export default class UserData extends Vue {
    @Prop({required:true})
    private value!:UserInfo;
    private userData:UserInfo = new UserInfo();
    private loading:boolean= false;
    private valid: boolean = false;

    @Ref()
    private confirmDialog !: PersistentDialog;

    @Watch('value', {deep:true})
    private valueChanged():void{

        this.userData = JSON.parse(JSON.stringify(this.value));
    }
    private async validate() {
        //@ts-ignore
        if (this.$refs.userDataForm.validate()) {
            try {
                this.loading = true;
                await this.$webApi.updateUserData(this.userData);

                this.$emit("input", this.userData);
            } catch {
                this.confirmDialog.showError(this.$i18n.tc('profileSettings.userData.alerts.saveError.title'),this.$i18n.tc('profileSettings.userData.alerts.saveError.message'));
            } finally {
                this.loading = false;
            }
        }
    }
    private get userDataHasChanged():boolean {
        return JSON.stringify(this.userData) !== JSON.stringify(this.value);
    }

    public reset(): void {

        // @ts-ignore
        this.$refs.userDataForm.reset();

    }
    mounted(){
        this.valueChanged();
    }
}
