















import {Component,Ref, Vue } from 'vue-property-decorator';
import InstallSnackbar from "@/components/global/installBar/InstallSnackbar.vue";
import {getJwtInfo, isLoggedIn, setSid, unsetSid} from '@/assets/utils/SessionUtils';
import {globalEventBus} from '@/main';

import BrowserUtility from "@/mixin/browserUtility";
import MoodaryHeader from "@/components/global/Header.vue"
import MoodaryFooter from "@/components/global/Footer.vue"
import ToTop from "@/components/global/ToTop.vue"
import OfflineDetector from "@/components/global/OfflineDetector.vue"
import RefreshPageDialog from "@/components/global/dialog/RefreshPageDialog.vue"

@Component({components: {InstallSnackbar, MoodaryHeader, MoodaryFooter, ToTop, OfflineDetector, RefreshPageDialog}})
export default class App extends Vue {
    private timer!: any;

    constructor() {
        super();
    }

    mounted() {
        globalEventBus.$on("loggOff", this.logOff);
        this.reloadTimer();
    }

    destroyed() {
        globalEventBus.$off("loggOff", this.logOff);
        if (this.timer) {
            clearTimeout(this.timer);
        }
        if (this.isLoggedIn) {
            this.logOff(false)
        }
    }

    private swipeRight() {
        if(navigator.onLine) {
            globalEventBus.$emit("swipeRight");
        }
    }
    private swipeLeft() {
        if(navigator.onLine) {
            globalEventBus.$emit("swipeLeft");
        }
    }

    private get isLoggedIn(): boolean {
        return isLoggedIn();
    }

    private async logOff(userDecision: boolean = false) {
        try {
            await this.$webApi.logout();
            unsetSid();
            if (userDecision) {
                const deviceConfig = BrowserUtility.getDeviceConfig(navigator, window);
                if (deviceConfig.isStandalone) {
                    this.$store.commit("SET_SAAPPUS", btoa(JSON.stringify({
                        email: undefined,
                        pass: undefined,
                        sid: undefined
                    })));
                }
            }
            this.$router.go(0);
        } catch { }
    }

    private async refreshToken() {
        const response = await this.$webApi.refreshToken();
        if (!response) {
            await this.logOff(false);
        } else {
            setSid(response);
            await this.reloadTimer();
        }
    }

    private reloadTimer() {
        try {
            if (this.timer) {
                clearTimeout(this.timer);
            }
            if (this.isLoggedIn) {
                const jwtInfo = getJwtInfo();
                if (jwtInfo && jwtInfo.exp > 0) {
                    let milliseconds = new Date(jwtInfo.exp * 1000).getTime() - new Date().getTime();
                    milliseconds = milliseconds - 2000;
                    //console.log("minutes to refresh: " + (milliseconds/60000));
                    this.timer = setTimeout(this.refreshToken, milliseconds);
                }
            }
        } catch {
            console.log(this.timer);
        }
    }
}
